export const POINTER_EVENTS = {
  POINTER_DOWN: {
    pointer: 'pointerdown',
    touch: 'touchstart',
  },
  POINTER_UP: {
    pointer: 'pointerup',
    touch: 'touchend',
  },
};

export function addPointerEventListener(target, event, callback) {
  if (window.PointerEvent) {
    target.addEventListener(event.pointer, callback);
  } else {
    target.addEventListener(event.touch, callback);
  }
}

export function removePointerEventListener(target, event, callback) {
  target.removeEventListener(event.pointer, callback);
  target.removeEventListener(event.touch, callback);
}
