/* eslint-disable object-curly-newline */
export default {
  fieldsets: [
    {
      name: 'account',
      label: 'Contact information',
      fields: [
        { name: 'Given_Name__c', label: 'First Name', validateRule: { required: 'Please enter first name' } },
        { name: 'Surname__c', label: 'Last Name', validateRule: { required: 'Please enter last name' } },
        {
          name: 'Email__c',
          label: 'E-mail',
          inputmode: 'email',
          validateRule: {
            required: 'Please enter an e-mail address',
            pattern: { value: /^\S+@\S+$/, message: 'Please enter valid e-mail address' },
          },
        },
        {
          name: 'MobilePhone__c',
          label: 'Phone Number',
          inputmode: 'tel',
          validateRule: {
            required: 'Please enter a phone number in (###) ###-#### format',
            pattern: { value: /^(?:\+\d)?\(?\d{3}\)?\s?\d{3}-?\d{4}$/, message: 'Please enter a phone number in (###) ###-#### format' },
          },
        },
      ],
    },
    {
      name: 'vehicle',
      label: 'Vehicle Information',
      fields: [
        {
          name: 'EPOO_VIN__c',
          label: 'Vehicle Identification Number (VIN)',
          validateRule: {
            required: 'Please enter valid VIN',
            pattern: { value: /^[A-Z0-9]{17}$/i, message: 'Please enter valid VIN' },
          },
          normalizeValue: (value) => (typeof value === 'string' ? value.toUpperCase() : value),
        },
      ],
    },
  ],
};
