import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useAnalytics, ErrorMessage } from '@cv/webframework-react-components';

import RightRailContent from '../../components/RightRailContent';

import Form from '../../components/Form';
import Confirmation from '../../components/Confirmation';

import { allowedEvents } from '../../util/analytics-allowed-events';

import './Home.css';

function Home({
  content: {
    rightHandRailContent,
    rightHandRailSuccessText,
    rightHandRailMobileBaseText,
    rightHandRailMobileExtraText,
    successText,
    formHeaderText,
    submitButtonText,
    fileUploadButtonText,
    reasonCodeErrors,
  },
  program,
}) {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { trackEvent } = useAnalytics();
  const [notifications, setNotifications] = useState([]);
  const handleErrorClose = (msg) => {
    if (msg) {
      setNotifications((prev) => (prev.filter(({ message }) => message !== msg)));
    }
  };

  const addNotification = useCallback((msg, canDismiss = true) => {
    const newNotification = {
      message: msg,
      canDismiss,
    };
    setNotifications((prev) => {
      const messageAlreadyShowed = prev.find(({ message }) => message === msg);
      return (messageAlreadyShowed ? prev : [...prev, newNotification]);
    });
  }, [setNotifications]);

  const railContent = [
    {
      document: rightHandRailContent,
      className: 'Home-rail-content--desktop',
    },
    {
      document: rightHandRailMobileBaseText,
      className: 'Home-rail-content--mobile',
    },
    {
      document: rightHandRailMobileExtraText,
      className: 'Home-rail-content--extra-text',
      isExpandable: true,
    },
  ];

  const railContentSuccess = [
    {
      document: rightHandRailSuccessText,
    },
  ];

  const handleSuccess = () => {
    setIsFormSubmitted(true);
  };

  useEffect(() => {
    trackEvent(allowedEvents.landingPageLoaded, 'pageView');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const reasonCode = urlSearchParams.get('reasoncode');
    if (reasonCode) {
      const errorMessage = reasonCodeErrors.find((e) => e.key === reasonCode)?.value;
      const defaultErrorMessage = reasonCodeErrors.find((e) => e.key === 'default')?.value;
      addNotification(errorMessage || defaultErrorMessage);
    }
  }, [addNotification, reasonCodeErrors]);

  return (
    <>
      <div className="Home">
        {isFormSubmitted
          && (
            <Helmet>
              <body className="Page--Confirmation" />
            </Helmet>
          )}

        <article className="Home-form">
          {isFormSubmitted && <Confirmation successText={successText} />}
          {!isFormSubmitted
            && (
              <Form
                onSuccess={handleSuccess}
                formHeaderText={formHeaderText}
                program={program}
                submitButtonText={submitButtonText}
                fileUploadButtonText={fileUploadButtonText}
                addNotification={addNotification}
              />
            )}
        </article>

        <aside className="Home-rail">
          <RightRailContent
            documents={isFormSubmitted ? railContentSuccess : railContent}
            program={program}
          />
        </aside>
      </div>
      {notifications.length
        ? (
          <div className="NotificationWrapper">
            {notifications.map(({ canDismiss, message }) => (
              <ErrorMessage
                type="inline"
                onDismiss={
                  canDismiss
                    ? () => handleErrorClose(message)
                    : null
                }
              >
                {message}
              </ErrorMessage>
            ))}
          </div>
        ) : null}
    </>
  );
}

Home.propTypes = {
  content: PropTypes.shape({
    rightHandRailContent: PropTypes.object,
    rightHandRailSuccessText: PropTypes.object,
    rightHandRailMobileBaseText: PropTypes.object,
    rightHandRailMobileExtraText: PropTypes.object,
    successText: PropTypes.object,
    formHeaderText: PropTypes.object,
    submitButtonText: PropTypes.string,
    fileUploadButtonText: PropTypes.string,
    programs: PropTypes.array,
    reasonCodeErrors: PropTypes.array,
  }).isRequired,
  program: PropTypes.object,
};

Home.defaultProps = {
  program: {},
};

export default Home;
