import React from 'react';
import Helmet from 'react-helmet';

import notFoundIcon from '../../assets/icons/404-not-found.svg';

import './NotFound.css';

const title = '404 - Page not found!';

export default function NotFound() {
  return (
    <div className="NotFound">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <img src={notFoundIcon} alt="Not found" />
      <h1>{title}</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}
