const selectProgram = (oemInfo, programs, cCase) => {
  if (programs.length < 2) {
    return programs[0] || {};
  }
  if (oemInfo.name === 'FCA') {
    if (cCase.MAKE__c === 'Alfa Romeo') {
      return programs.find((e) => e.name === 'Alfa Connect');
    }
    return programs.find((e) => e.name !== 'Alfa Connect');
  }
  return programs[0];
};

export default selectProgram;
