import React from 'react';
import PropTypes from 'prop-types';
import httpStatus from '../Form/httpStatuses';

import './Progress.css';

const statusToMessageMap = {
  [httpStatus.SUCCESS]: 'Done!',
  [httpStatus.FAILED]: 'Failed',
  [httpStatus.LOADING]: 'Uploading...',
  [httpStatus.OVERSIZE]: 'File cannot be greater than 5MB',
};
function Progress({ progress, status }) {
  const isFailed = [httpStatus.OVERSIZE, httpStatus.FAILED].includes(status);

  return (
    <div className="Progress">
      <progress value={progress} max="100">
        {progress}
        {' '}
        %
      </progress>
      {progress && (
        <p className="Progress-status" style={isFailed ? { color: 'red' } : {}}>
          {statusToMessageMap[status]}
        </p>
      )}
    </div>
  );
}

Progress.propTypes = {
  progress: PropTypes.number,
  status: PropTypes.string,
};

Progress.defaultProps = {
  progress: 0,
  status: httpStatus.LOADING,
};

export default Progress;
