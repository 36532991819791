import axios from 'axios';

export default class HttpService {
  constructor({ env, apiBaseUrl }) {
    this.env = env;
    this.apiBaseUrl = apiBaseUrl;

    this.cancelTokens = new Map();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const uuid = urlSearchParams.get('UUID');
    const caseId = urlSearchParams.get('CaseId');

    this.axiosInstance = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        'X-Salesforce-UUID': uuid,
        'X-Salesforce-CaseId': caseId,
      },
    });
  }

  getCaseDetails() {
    return this.axiosInstance.get('/case/');
  }

  updateCaseDetails(data) {
    return this.axiosInstance.patch('/case/', data);
  }

  uploadDocument(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('Type', file.type);
    formData.append('Name', file.name);
    formData.append('Body', file);

    const cancelToken = axios.CancelToken.source();
    this.cancelTokens.set(file, cancelToken);

    return this.axiosInstance
      .post('/attachment/', formData, { onUploadProgress, cancelToken: cancelToken.token })
      .then((result) => {
        this.cancelTokens.delete(file);
        return result;
      });
  }

  cancelDocumentUploading(file) {
    const cancelToken = this.cancelTokens.get(file);
    if (!cancelToken) {
      return false;
    }

    cancelToken.cancel('Canceled by user');
    this.cancelTokens.delete(file);
    return true;
  }

  removeDocument(documentId) {
    return this.axiosInstance.delete(`/attachment/${documentId}/`);
  }
}
