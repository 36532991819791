import React from 'react';
import PropTypes from 'prop-types';

export { default as Api } from './Api';
export { HttpService, MockService } from './services';

const ApiContext = React.createContext();

export const useApi = () => React.useContext(ApiContext);

export const ApiProvider = ({ api, children }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

ApiProvider.propTypes = {
  api: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
