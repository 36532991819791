import React from 'react';

function ConfirmationIcon() {
  return (
    <figure className="Confirmation-image">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 70">
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="13"
          d="M4.5,39.4l21.9,21l52.1-56"
        />
      </svg>
    </figure>
  );
}

export default ConfirmationIcon;
