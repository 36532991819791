import React from 'react';
import PropTypes from 'prop-types';

import DocumentUploadIcon from './DocumentUploadIcon';
import Progress from '../Progress';
import httpStatus from '../Form/httpStatuses';

function DocumentUploadProgress({
  fileName, progress, onUploadCancel, onUploadRemove, status,
}) {
  const remove = {
    label: 'Remove',
    onClick: onUploadRemove,
  };
  const buttons = {
    [httpStatus.LOADING]: {
      label: 'Cancel',
      onClick: onUploadCancel,
    },
    [httpStatus.SUCCESS]: remove,
    [httpStatus.FAILED]: remove,
    [httpStatus.OVERSIZE]: remove,
  };

  const { onClick, label } = buttons[status];
  const extIndex = fileName.lastIndexOf('.') + 1;
  const extension = extIndex > 0 ? fileName.substr(extIndex) : '?';

  return (
    <div className="DocumentUpload-progress">
      <DocumentUploadIcon label={extension} />
      <div className="DocumentUpload-progress-wrapper">
        <div className="DocumentUpload-progress-header">
          <h4 className="DocumentUpload-progress-filename">{fileName}</h4>
          <button
            type="button"
            className="DocumentUpload-progress-button"
            onClick={onClick}
          >
            {label}
          </button>
        </div>
        <Progress progress={progress} status={status} />
      </div>
    </div>
  );
}

DocumentUploadProgress.propTypes = {
  fileName: PropTypes.string.isRequired,
  progress: PropTypes.number,
  onUploadCancel: PropTypes.func,
  onUploadRemove: PropTypes.func,
  status: PropTypes.string.isRequired,
};

DocumentUploadProgress.defaultProps = {
  progress: 0,
  onUploadCancel: () => {},
  onUploadRemove: () => {},
};

export default DocumentUploadProgress;
