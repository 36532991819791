export function validateFileFormat({ name }, { formats }) {
  return (new RegExp(`(${formats.join('|').replace(/\./g, '\\.')})$`)).test(name);
}

export function validateMaxFilesNumber(fileList, maxFilesToUpload) {
  if (fileList.length && fileList.length > maxFilesToUpload) {
    return false;
  }
  return true;
}
