export const THEME_CONFIG = {
  assets: [
    {
      propName: 'fontRegular',
      type: 'font',
      fontName: 'CustomFont',
    },
    {
      propName: 'fontItalics',
      type: 'font',
      fontName: 'CustomFontItalic',
    },
    {
      propName: 'fontBold',
      type: 'font',
      fontName: 'CustomFontBold',
    },
  ],
  vars: [
    {
      propName: 'accentColor',
      cssVarName: '--accent-color',
    }, {
      propName: 'urlColor',
      cssVarName: '--url-color',
    }, {
      propName: 'bannerTextAlignment',
      cssVarName: '--banner-text-alignment',
    }, {
      propName: 'bannerFontColor',
      cssVarName: '--banner-color',
    }, {
      propName: 'bannerFontSize',
      cssVarName: '--banner-font-size',
    }, {
      propName: 'bannerHeaderFontSize',
      cssVarName: '--banner-header-font-size',
    }, {
      propName: 'bannerBackgroundColor',
      cssVarName: '--banner-background-color',
    }, {
      propName: 'bannerConfirmationBackgroundColor',
      cssVarName: '--banner-confirmation-background-color',
    }, {
      propName: 'buttonBackgroundColor',
      cssVarName: '--button-background-color',
    }, {
      propName: 'buttonFontColor',
      cssVarName: '--button-color',
    }, {
      propName: 'buttonFontSize',
      cssVarName: '--button-font-size',
    }, {
      propName: 'formBrowseButtonBackgroundColor',
      cssVarName: '--form-browse-button-background-color',
    }, {
      propName: 'formBrowseButtonBorderColor',
      cssVarName: '--form-browse-button-border-color',
    }, {
      propName: 'formBrowseButtonFontColor',
      cssVarName: '--form-browse-button-color',
    }, {
      propName: 'formBrowseButtonHoverBackgroundColor',
      cssVarName: '--form-browse-button-hover-background-color',
    }, {
      propName: 'formBrowseButtonHoverBorderColor',
      cssVarName: '--form-browse-button-hover-border-color',
    }, {
      propName: 'formBrowseButtonHoverFontColor',
      cssVarName: '--form-browse-button-hover-color',
    }, {
      propName: 'formBrowseButtonFontSize',
      cssVarName: '--form-browse-button-font-size',
    }, {
      propName: 'formHeaderFontColor',
      cssVarName: '--form-header-color',
    }, {
      propName: 'formHeaderFontSize',
      cssVarName: '--form-header-font-size',
    }, {
      propName: 'formInputBackgroundColor',
      cssVarName: '--form-input-background-color',
    }, {
      propName: 'formInputBorderColor',
      cssVarName: '--form-input-border-color',
    }, {
      propName: 'formInputFontColor',
      cssVarName: '--form-input-color',
    }, {
      propName: 'formInputFontSize',
      cssVarName: '--form-input-font-size',
    }, {
      propName: 'formMainFontColor',
      cssVarName: '--form-main-color',
    }, {
      propName: 'formMainFontSize',
      cssVarName: '--form-main-font-size',
    }, {
      propName: 'rightHandRailBackgroundColor',
      cssVarName: '--right-hand-rail-background-color',
    }, {
      propName: 'rightHandRailFontColor',
      cssVarName: '--right-hand-rail-color',
    }, {
      propName: 'rightHandRailFontSize',
      cssVarName: '--right-hand-rail-font-size',
    }, {
      propName: 'rightHandRailHeaderFontSize',
      cssVarName: '--right-hand-rail-header-font-size',
    }, {
      propName: 'footerFontColor',
      cssVarName: '--footer-font-color',
    }, {
      propName: 'footerURLFontColor',
      cssVarName: '--footer-url-font-color',
    }, {
      propName: 'footerFontSize',
      cssVarName: '--footer-font-size',
    }, {
      propName: 'footerBackgroundColor',
      cssVarName: '--footer-background-color',
    }, {
      propName: 'footerTextAlignment',
      cssVarName: '--footer-text-alignment',
    }, {
      propName: 'bannerFontSizeMobile',
      cssVarName: '--banner-font-size-mobile',
    }, {
      propName: 'bannerHeaderFontSizeMobile',
      cssVarName: '--banner-header-font-size-mobile',
    }, {
      propName: 'buttonFontSizeMobile',
      cssVarName: '--button-font-size-mobile',
    }, {
      propName: 'formBrowseButtonFontSizeMobile',
      cssVarName: '--form-browse-button-font-size-mobile',
    }, {
      propName: 'formHeaderFontSizeMobile',
      cssVarName: '--form-header-font-size-mobile',
    }, {
      propName: 'formInputFontSizeMobile',
      cssVarName: '--form-input-font-size-mobile',
    }, {
      propName: 'formMainFontSizeMobile',
      cssVarName: '--form-main-font-size-mobile',
    }, {
      propName: 'rightHandRailFontSizeMobile',
      cssVarName: '--right-hand-rail-font-size-mobile',
    }, {
      propName: 'rightHandRailHeaderFontSizeMobile',
      cssVarName: '--right-hand-rail-header-font-size-mobile',
    }, {
      propName: 'footerFontSizeMobile',
      cssVarName: '--footer-font-size-mobile',
    },
  ],
  specialVars: [
    {
      propName: 'bannerContentAlignment',
      cssVarName: '--banner-content-alignment',
      values: {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      },
    }, {
      propName: 'bannerBottomBorder',
      cssVarName: '--banner-bottom-border',
      values: {
        true: '1px',
        false: '0px',
      },
    }, {
      propName: 'buttonCornerStyle',
      cssVarName: '--button-border-radius',
      values: {
        Rounded: '5px',
        'Right angle': 'none',
        'Half circle': '24px',
      },
    }, {
      propName: 'formBrowseButtonCornerStyle',
      cssVarName: '--form-browse-button-border-radius',
      values: {
        Rounded: '5px',
        'Right angle': 'none',
        'Half circle': '28px',
      },
    }, {
      propName: 'formInputCornerStyle',
      cssVarName: '--form-input-border-radius',
      values: {
        Rounded: '4px',
        'Right angle': 'none',
        'Half circle': '24px',
      },
    }, {
      propName: 'rightHandRailCornerStyle',
      cssVarName: '--right-hand-rail-border-radius',
      values: {
        Rounded: '6px',
        'Right angle': 'none',
        'Half circle': '30px',
      },
    },
  ],
};
