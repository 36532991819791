import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@cv/webframework-react-components';

import InputFile from '../InputFile';

import nextId from '../../util/next-id';
import { validateFileFormat, validateMaxFilesNumber } from './helpers';

import './DocumentUpload.css';

const errors = {
  required: 'No documents attached',
  maxFilesExceeded: 'You can attach up to 3 documents',
  invalidFormat: 'Attached format is not supported',
};

const maxFilesToUpload = 3;

const DocumentUpload = ({
  name, label, formats, error, onChange, inputRef,
}) => {
  const inputId = nextId();
  const [validationErrors, setValidationErrors] = useState([]);

  const documentsValidation = (files) => {
    const validationSettings = { formats };

    setValidationErrors([]);

    if (!validateMaxFilesNumber(files, maxFilesToUpload)) {
      setValidationErrors([{ type: 'maxFilesExceeded' }]);
      return [];
    }

    const validatedFiles = [...files].filter((file) => {
      if (validateFileFormat(file, validationSettings)) {
        return file;
      }
      setValidationErrors(
        (prevState) => [...prevState, { message: `${errors.invalidFormat} - ${file.name}` }],
      );
      return null;
    });

    return validatedFiles;
  };

  const handleChange = (ev) => {
    const { files } = ev.target;
    const validatedFiles = documentsValidation(files);

    onChange(validatedFiles, maxFilesToUpload);
  };

  return (
    <div className="DocumentUpload">
      <div className="DocumentUpload-wrapper">
        <label className="DocumentUpload-label" htmlFor={inputId}>
          {label}
          <InputFile
            name={name}
            id={inputId}
            className="visually-hidden"
            ref={inputRef}
            accept={formats}
            onChange={handleChange}
          />
        </label>
        <div>
          Formats available for upload:
          <br />
          {formats.join(', ')}
        </div>
      </div>

      {error && error.type in errors && (
        <ErrorMessage type="inline">
          {error.message || errors[error.type]}
        </ErrorMessage>
      )}

      {validationErrors.length > 0
        && validationErrors.map((validationError) => {
          const errorMessage = validationError.message || errors[validationError.type];
          return (
            <ErrorMessage type="inline" key={errorMessage}>
              {errorMessage}
            </ErrorMessage>
          );
        })}
    </div>
  );
};

DocumentUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formats: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape({
    type: PropTypes.string.isRequired,
    ref: PropTypes.object,
    types: PropTypes.object,
    message: PropTypes.string,
  }),
  onChange: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
};

DocumentUpload.defaultProps = {
  label: 'Browse documents',
  formats: ['.jpg', '.png', '.pdf', '.bmp', '.gif'],
  error: null,
  onChange: () => {},
  inputRef: null,
};

export default DocumentUpload;
