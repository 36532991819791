import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ContentfulRichText, ShortcodeParser } from '@cv/webframework-react-components';

import { SHORTCODE_CONFIG } from '../../util/shortcode-config';

function RightRailContent({ documents, program }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleRailContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {documents.map((rail) => {
        const { className, document, isExpandable } = rail;
        const expandedClassName = clsx({ expanded: isExpanded && isExpandable });
        const railClassName = clsx(`Home-rail-content ${className}`, expandedClassName);
        const shortcodes = ShortcodeParser.generateShortcodeValues(SHORTCODE_CONFIG, program);
        if (document) {
          return (
            <Fragment key={railClassName}>
              <div className={railClassName}>
                <ContentfulRichText document={document} shortcodes={shortcodes} />
              </div>

              {isExpandable && (
                <button
                  type="button"
                  onClick={toggleRailContent}
                  className={`Home-rail-content-toggle ${expandedClassName}`}
                >
                  <span>Learn more</span>
                  <span>Learn less</span>
                </button>
              )}
            </Fragment>
          );
        }

        return null;
      })}
    </>
  );
}

RightRailContent.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  program: PropTypes.object.isRequired,
};

export default RightRailContent;
