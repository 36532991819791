import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ContentfulRichText } from '@cv/webframework-react-components';
import ConfirmationIcon from './ConfirmationIcon';

import './Confirmation.css';

const Confirmation = ({ successText }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Confirmation">
      <ConfirmationIcon />
      <ContentfulRichText document={successText} />
    </div>
  );
};

Confirmation.propTypes = {
  successText: PropTypes.object.isRequired,
};

export default Confirmation;
