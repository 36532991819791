import React, { useEffect, createRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { addPointerEventListener, removePointerEventListener, POINTER_EVENTS } from '../../util/pointer-events';

import './Tooltip.css';

export default function Tooltip({
  children, position, label, ...restProps
}) {
  const tooltipPositionClass = clsx(
    `Tooltip-position--${position}`,
  );

  const { body } = document;
  const getWindowWidth = () => window.innerWidth;

  const tooltipTriggerRef = createRef();
  const tooltipContentRef = createRef();

  const [contentStyle, setContentStyle] = useState({});
  const [arrowStyle, setArrowStyle] = useState({});
  const [positionClass, setPositionClass] = useState(tooltipPositionClass);
  const [isVisible, setIsVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  const onDocumentClick = (ev) => {
    const { target } = ev;
    if (target && (target !== tooltipTriggerRef.current || !target.closest('.Tooltip'))) {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const resetTooltipSettings = () => {
    setContentStyle({});
    setArrowStyle({});
    setPositionClass(tooltipPositionClass);
  };

  useEffect(() => {
    addPointerEventListener(body, POINTER_EVENTS.POINTER_DOWN, onDocumentClick);
    window.addEventListener('orientationchange', resetTooltipSettings, false);

    return () => {
      removePointerEventListener(body, POINTER_EVENTS.POINTER_DOWN, onDocumentClick);
      window.removeEventListener('orientationchange', resetTooltipSettings, false);
    };
  });

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(getWindowWidth());
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const screenPadding = 16; // 1em
    const tooltipTriggerPosition = tooltipTriggerRef.current.getBoundingClientRect();
    const tooltipContentPosition = tooltipContentRef.current.getBoundingClientRect();
    const tooltipTriggerWidth = tooltipTriggerPosition.width;

    const triggerRightPosition = tooltipTriggerPosition.x + tooltipTriggerWidth;
    const contentRightPosition = tooltipContentPosition.x + tooltipContentPosition.width
    + screenPadding * 2;

    if (tooltipContentPosition.x < 0) {
      setContentStyle({
        left: '0',
        right: 'auto',
        transform: `translateX(${-tooltipTriggerPosition.x + screenPadding}px)`,
      });
      setArrowStyle({
        left: '0',
        right: 'auto',
        transform: `translateX(${tooltipTriggerPosition.x - tooltipTriggerWidth}px)`,
      });
    }

    if (contentRightPosition > windowWidth) {
      setContentStyle({
        left: 'auto',
        right: '0',
        transform: `translateX(${(windowWidth - triggerRightPosition) - (screenPadding * 2)}px)`,
      });
      setArrowStyle({
        left: 'auto',
        right: '0',
        transform: `translateX(${-(windowWidth - triggerRightPosition) + (screenPadding + tooltipTriggerWidth)}px)`,
      });
    }
  }, [isVisible, windowWidth, tooltipTriggerRef, tooltipContentRef]);

  useEffect(() => {
    setPositionClass(`${tooltipPositionClass} ${isVisible ? 'Tooltip--is-visible' : ''}`);
  }, [tooltipPositionClass, isVisible]);

  return (
    <span className="Tooltip">
      <button
        type="button"
        onClick={handleClick}
        ref={tooltipTriggerRef}
        className="Tooltip-trigger"
      >
        {label}
      </button>

      <span
        {...restProps}
        ref={tooltipContentRef}
        style={contentStyle}
        className={clsx('Tooltip-content', positionClass)}
      >
        <span className={clsx('Tooltip-arrow', positionClass)} style={arrowStyle} />
        {children}
      </span>
    </span>
  );
}

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
};

Tooltip.defaultProps = {
  position: 'top',
};
