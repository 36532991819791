export default class Api {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getCaseDetails() {
    return this.httpService.getCaseDetails();
  }

  updateCaseDetails(data) {
    return this.httpService.updateCaseDetails(data);
  }

  uploadDocument(file, onUploadProgress) {
    return this.httpService.uploadDocument(file, onUploadProgress);
  }

  cancelDocumentUploading(file) {
    return this.httpService.cancelDocumentUploading(file);
  }

  removeDocument(documentId) {
    return this.httpService.removeDocument(documentId);
  }
}
