const FAILED = 'failed';
const SUCCESS = 'success';
const LOADING = 'loading';
const OVERSIZE = 'oversize';
const PENDING = 'pending';

export default {
  FAILED,
  SUCCESS,
  LOADING,
  OVERSIZE,
  PENDING,
};
