import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ContentfulRichText, ShortcodeParser } from '@cv/webframework-react-components';

import { SHORTCODE_CONFIG } from '../../util/shortcode-config';
import './Footer.css';

const Footer = ({ content: { footerText }, program }) => {
  const backgroundColor = getComputedStyle(document.body).getPropertyValue('--footer-background-color').trim().toLowerCase();
  const hasExtraPadding = backgroundColor !== '#fff' && backgroundColor !== '#ffffff';

  return (
    <footer className={clsx('Footer', { 'Footer--extra-padding': hasExtraPadding })}>
      <div className="Footer-description">
        <ContentfulRichText
          document={footerText}
          shortcodes={ShortcodeParser.generateShortcodeValues(SHORTCODE_CONFIG, program)}
        />
      </div>
      <div className="Footer-links">
        { program?.footerLinks?.map((e) => (
          <a href={e.url} key={e.name.split(' ').join('-')} target="_blank" rel="noopener noreferrer">
            {e.label}
          </a>
        )) }
      </div>
    </footer>
  );
};

Footer.propTypes = {
  content: PropTypes.object,
  program: PropTypes.object,
};

Footer.defaultProps = {
  content: undefined,
  program: undefined,
};

export default Footer;
