import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ContentfulImage } from '@cv/webframework-react-components';

import './Banner.css';

const Banner = ({
  backgroundImage,
  backgroundImageMobile,
  logoImage,
  header,
  copy,
}) => {
  document.documentElement.style.setProperty('--banner-background-image', `url(${backgroundImage.file?.url})`);
  document.documentElement.style.setProperty('--banner-background-image-mobile', `url(${backgroundImageMobile.file?.url || backgroundImage.file?.url})`);

  return (
    <div className="TopBanner">
      <div className="TopBanner-inner">
        <div className="TopBanner-content">
          <ContentfulImage className="TopBanner-logo" imageData={logoImage} />
          <h1>{header}</h1>
          <p>{copy}</p>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  backgroundImage: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.object,
  }),
  backgroundImageMobile: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.object,
  }),
  logoImage: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.object,
  }),
  header: PropTypes.string,
  copy: PropTypes.string,
};

Banner.defaultProps = {
  backgroundImage: {
    title: '',
    file: null,
  },
  backgroundImageMobile: {
    title: '',
    file: null,
  },
  logoImage: {
    title: '',
    file: null,
  },
  header: '',
  copy: '',
};

export default Banner;
