import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';

function DocumentUploadIcon({ label }) {
  const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path fill="#FFF" stroke="#707070" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M0 0L15.273 0 22.909 7.636 22.909 28 0 28z" transform="translate(-79 -1000) translate(67 988) translate(12 12)" />
              <path fill="#FFF" stroke="#707070" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M15.273 0L22.909 7.636 15.273 7.636z" transform="translate(-79 -1000) translate(67 988) translate(12 12)" />
              <path fill="#707070" d="M0 12H23V21H0z" transform="translate(-79 -1000) translate(67 988) translate(12 12)" />
              <text fill="#FFF" fontFamily="SFUIText-Heavy, SF UI Text" fontSize="7" fontWeight="600" letterSpacing="1" transform="translate(-79 -1000) translate(67 988) translate(12 12)">
                <tspan x="3" y="20">{label.toUpperCase()}</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  const svgString = encodeURIComponent(renderToStaticMarkup(<UploadIcon />));
  const dataUri = `data:image/svg+xml,${svgString}`;

  return (
    <figure className="DocumentUpload-progress-icon">
      <img src={dataUri} alt={`${label} file`} />
    </figure>
  );
}

DocumentUploadIcon.propTypes = {
  label: PropTypes.string.isRequired,
};

export default DocumentUploadIcon;
