import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ContentfulConnect, ThemeLoader, useAnalytics, useAnalyticsClickHandler } from '@cv/webframework-react-components';

import { useApi } from '../../api';
import NotFound from '../../pages/NotFound';
import Home from '../../pages/Home';

import Banner from '../Banner';
import Footer from '../Footer';

import { THEME_CONFIG } from '../../util/theme-config';
import getPageId from '../../util/get-page-id';
import selectProgram from '../../util/select-program';

import './App.css';

const { hostname } = window.location;
const pageIdParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get('PageId');
};

export function App({ pageContent }) {
  const {
    title,
    oem,
    bannerImage,
    bannerImageMobile,
    logoImage,
    headerText,
    subheaderText,
    programs = [],
  } = pageContent;
  const { theme, faviconFiles = [] } = oem;
  const api = useApi();
  const [program, setProgram] = useState({});
  const { trackEvent } = useAnalytics();

  useAnalyticsClickHandler(trackEvent);

  useEffect(() => {
    const assignProgram = async () => {
      try {
        if (programs.length) {
          const { data: caseDetails } = await api.getCaseDetails();
          setProgram(selectProgram(oem, programs, caseDetails));
        }
      } catch (e) {
        setProgram(programs[0] || {});
      }
    };
    assignProgram();
  }, [api, programs, oem]);

  return (
    <ThemeLoader theme={theme} config={THEME_CONFIG}>
      <Router>
        <div className="App">
          <Helmet>
            <title>{title}</title>
            { faviconFiles.map((icon) => (
              <link key={`favicon-${icon.title}`} rel="icon" type={icon?.file?.contentType} href={`https:${icon?.file?.url}`} />
            )) }
          </Helmet>
          <header className="App-header">
            <Banner
              backgroundImage={bannerImage}
              backgroundImageMobile={bannerImageMobile}
              logoImage={logoImage}
              header={headerText}
              copy={subheaderText}
            />
          </header>
          <main className="App-main">
            <Switch>
              <Route path={['/', '/ownership']} exact>
                <Home content={pageContent} program={program} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </main>
          <Footer content={pageContent} program={program} />
        </div>
      </Router>
    </ThemeLoader>
  );
}

App.propTypes = {
  pageContent: PropTypes.shape({
    oem: PropTypes.shape({
      theme: PropTypes.object,
      faviconFiles: PropTypes.array,
    }),
    title: PropTypes.string,
    bannerImage: PropTypes.object,
    bannerImageMobile: PropTypes.object,
    logoImage: PropTypes.object,
    headerText: PropTypes.string,
    subheaderText: PropTypes.string,
    programs: PropTypes.array,
  }),
};

App.defaultProps = {
  pageContent: {
    oem: {
      faviconFiles: [],
    },
    programs: [],
    title: 'Loading',
  },
};

export default ContentfulConnect(App, {
  pageContent: { id: getPageId(hostname, pageIdParam()) },
});
