import React from 'react';
import PropTypes from 'prop-types';

const InputFile = React.forwardRef(({
  name, id, className, accept, onChange, multiple, disabled,
}, ref) => (
  <input
    name={name}
    id={id}
    className={className}
    ref={ref}
    type="file"
    accept={accept && accept.join(', ')}
    onChange={onChange}
    multiple={multiple}
    disabled={disabled}
    encType="multipart/form-data"
  />
));

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

InputFile.defaultProps = {
  id: '',
  className: '',
  multiple: true,
  accept: undefined,
  disabled: false,
  onChange: () => {},
};

export default InputFile;
