import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ContentfulInit, ErrorBoundary, Analytics } from '@cv/webframework-react-components';

import { HttpService, MockService, Api, ApiProvider } from './api';

import App from './components/App';
import { allowedEvents } from './util/analytics-allowed-events';

import 'normalize.css';
import './index.css';

// Contentful settings
const contentfulConfig = {
  spaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
};

// API settings
const env = process.env.REACT_APP_API_ENV;
const apiBaseUrl = process.env[`REACT_APP_${env}_API_BASE_URL`];

const httpService = env === 'MOCK'
  ? new MockService()
  : new HttpService({ env, apiBaseUrl });
const api = new Api(httpService);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ContentfulInit {...contentfulConfig}>
        <Analytics
          allowedEvents={allowedEvents}
          url={process.env.REACT_APP_ANALYTICS_URL}
        >
          <ApiProvider api={api}>
            <App />
          </ApiProvider>
        </Analytics>
      </ContentfulInit>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
