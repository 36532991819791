import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import {
  ContentfulRichText,
  ShortcodeParser,
  Loader,
  InputText,
  useAnalytics,
} from '@cv/webframework-react-components';

import { SHORTCODE_CONFIG } from '../../util/shortcode-config';
import { allowedEvents } from '../../util/analytics-allowed-events';
import { useApi } from '../../api';
import DocumentUpload, { DocumentUploadProgress } from '../DocumentUpload';
import Tooltip from '../Tooltip';

import fields from './fields';
import httpStatus from './httpStatuses';

import './Form.css';

const Form = ({
  onSuccess,
  formHeaderText,
  submitButtonText,
  fileUploadButtonText,
  program,
  addNotification,
}) => {
  const api = useApi();
  const [attachedDocuments, setAttachedDocuments] = useState([]);
  const [caseDetails, setCaseDetails] = useState({});
  const [status, setStatus] = useState(httpStatus.PENDING);
  const { register, handleSubmit, errors, control, reset, setError: setFormError } = useForm();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const loadAccountDetails = async () => {
      try {
        setStatus(httpStatus.LOADING);
        const { data } = await api.getCaseDetails();
        setCaseDetails(data);
        reset(data);
        setStatus(httpStatus.PENDING);
      } catch (e) {
        addNotification('Unable to load user data', false);
        setStatus(httpStatus.FAILED);
      }
    };
    loadAccountDetails();
  }, [api, reset, addNotification]);

  const onSubmit = async (data) => {
    trackEvent(allowedEvents.clickSubmitButton);

    const filteredDocuments = attachedDocuments.filter(
      (document) => document.status !== httpStatus.FAILED,
    );
    if (filteredDocuments.length === 0) {
      setFormError(
        'documents',
        { type: 'required', message: attachedDocuments.length > 0 ? 'Please upload valid files' : '' },
      );
      return;
    }

    const caseData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key in caseDetails) {
        caseData.set(key, value);
      }
    });

    try {
      setStatus(httpStatus.LOADING);
      await api.updateCaseDetails(caseData);
      onSuccess();
    } catch (e) {
      addNotification('Unable to submit form. Please try later.');
      setStatus(httpStatus.PENDING);
    }
  };

  const filterPreviouslyAttachedFiles = (files) => files.filter(
    (file) => !attachedDocuments.some(
      (document) => document?.file?.name === file.name,
    ),
  );

  const handleDocumentCancel = (file) => {
    if (api.cancelDocumentUploading(file)) {
      setAttachedDocuments((documents) => documents.filter((document) => document.file !== file));
    } else {
      addNotification('Unable to cancel uploading');
    }
  };

  const handleDocumentRemove = async (file) => {
    const { id } = file;
    if (!id) {
      setAttachedDocuments(
        (documents) => documents.filter((document) => document.file !== file),
      );
      return;
    }

    try {
      setStatus(httpStatus.LOADING);
      await api.removeDocument(id);
    } catch (e) {
      addNotification('Unable to remove document. Please try again later');
    } finally {
      setStatus(httpStatus.PENDING);
      setAttachedDocuments((documents) => documents.filter((document) => document.id !== id));
    }
  };

  const setFileData = (fileName, data) => {
    setAttachedDocuments((prevAttachedDocuments) => prevAttachedDocuments.map(
      (document) => (
        document.file.name === fileName ? { ...document, ...data } : document
      ),
    ));
  };

  const uploadDocument = async (file) => {
    const { name: uploadFileName } = file;

    const onUploadProgress = (event) => {
      const progress = Math.round((100 * event.loaded) / event.total);
      setFileData(uploadFileName, { progress, status: httpStatus.LOADING });
    };

    try {
      const { data: { id } } = await api.uploadDocument(file, onUploadProgress);
      setFileData(uploadFileName, { id, status: httpStatus.SUCCESS });
    } catch (e) {
      // eslint-disable-next-line no-underscore-dangle
      if (e.__CANCEL__) {
        // Uploading is canceled by user
        return;
      }

      const isFileSizeError = e.response.status === 413;

      setFileData(file.name, {
        status: isFileSizeError
          ? httpStatus.OVERSIZE
          : httpStatus.FAILED,
      });
      addNotification(
        isFileSizeError
          ? 'Upload Failed: Files cannot be greater than 5MB'
          : 'Unable to attach document. Please try again later',
      );
    }
  };

  const handleDocumentAttach = (files, maxFilesToUpload) => {
    if (attachedDocuments.length + files.length > maxFilesToUpload) {
      addNotification(`Unable to upload more than ${maxFilesToUpload} documents`);
      return false;
    }

    const attachedFiles = filterPreviouslyAttachedFiles(files).map(
      (file) => ({ file, progress: 0, status: httpStatus.LOADING }),
    );

    setAttachedDocuments(
      (prevState) => [...prevState, ...attachedFiles],
    );

    Promise.all(attachedFiles.map((document) => uploadDocument(document.file)));

    return false;
  };

  return (
    <form className="Form" onSubmit={handleSubmit(onSubmit)}>
      {status === httpStatus.LOADING && <Loader />}

      <ContentfulRichText
        document={formHeaderText}
        shortcodes={ShortcodeParser.generateShortcodeValues(SHORTCODE_CONFIG, program)}
      />

      {fields.fieldsets.map((fieldset) => (
        <fieldset key={fieldset.name} className={clsx('Form-fieldset', `Form-fieldset--${fieldset.name}`)}>
          <legend className="Form-legend">{fieldset.label}</legend>
          <div className="Form-fields">
            {fieldset.fields.map((field) => (
              <Controller
                key={field.id || field.name}
                control={control}
                name={field.name}
                rules={field.validateRule}
                defaultValue={caseDetails[field.name] || ''}
                render={({ value, onChange, ...rest }) => (
                  <InputText
                    label={field.label}
                    type={field.type}
                    value={value}
                    inputmode={field.inputmode || field.type}
                    required={
                      field.required || Boolean(field.validateRule && field.validateRule.required)
                    }
                    error={errors[field.name]?.message}
                    onChange={(e) => {
                      const { value: val } = e.target;
                      if (typeof field.normalizeValue === 'function') {
                        onChange(field.normalizeValue(val));
                      } else {
                        onChange(val);
                      }
                    }}
                    {...rest}
                  />
                )}
              />
            ))}
          </div>
        </fieldset>
      ))}

      <fieldset className="Form-fieldset">
        <legend className="Form-legend">Proof of Ownership Documentation</legend>
        <div className="Form-label">
          Please attach one or more documents to verify your ownership.
          <Tooltip label="i">
            <div>List of accepted documents:</div>
            <ul>
              <li>Bill of Sale</li>
              <li>Vehicle Registration</li>
              <li>Vehicle Title</li>
              <li>Vehicle Purchase Agreement</li>
              <li>Vehicle Lease or Finance Contract</li>
              <li>Death Certificate</li>
            </ul>
          </Tooltip>
        </div>
        <DocumentUpload
          name="documents"
          onChange={handleDocumentAttach}
          error={errors.documents}
          label={fileUploadButtonText}
          inputRef={register({
            required: true,
          })}
        />
        {attachedDocuments.length > 0 && (
          <ul className="Form-progress-list">
            {attachedDocuments.map((documentData) => (
              <li key={documentData.file.name}>
                <DocumentUploadProgress
                  fileName={documentData.file.name}
                  progress={documentData.progress}
                  status={documentData.status}
                  onUploadCancel={() => handleDocumentCancel(documentData.file)}
                  onUploadRemove={() => handleDocumentRemove(documentData.file)}
                />
              </li>
            ))}
          </ul>
        )}
      </fieldset>

      <div className={clsx('Form-privacy-policy', { 'Form-privacy-policy--error': Boolean(errors.privacyPolicy) })}>
        <input type="checkbox" id="privacy-policy" name="privacyPolicy" ref={register({ required: true })} />
        <label htmlFor="privacy-policy">
          I confirm the information provided is true and accurate,
          that I am the owner of the vehicle listed above,and that I am at least 18 years old,
          or the age of majority, as determined by the laws of my state of residency.
        </label>
      </div>

      <button type="submit" className="Form-submit">{submitButtonText}</button>
    </form>
  );
};

Form.propTypes = {
  formHeaderText: PropTypes.object,
  fileUploadButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSuccess: PropTypes.func,
  program: PropTypes.object,
  addNotification: PropTypes.func,
};

Form.defaultProps = {
  formHeaderText: {},
  fileUploadButtonText: 'Browse Documents',
  submitButtonText: 'Submit',
  onSuccess: () => { },
  program: {},
  addNotification: () => { },
};

export default Form;
